var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "Find a club to join" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: 1 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 18 } },
            [
              _c("a-input", {
                attrs: { type: "email", placeholder: "Search for a club" },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 6 } },
            [
              _c(
                "a-button",
                {
                  attrs: { block: "", type: "primary" },
                  on: { click: _vm.searchClubs },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._l(_vm.clubs, function (club) {
            return _c(
              "a-row",
              { key: club.id, attrs: { type: "flex", gutter: 4 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _vm._v("\n        " + _vm._s(club.club_name) + "\n      "),
                ]),
                _c("a-col", { attrs: { span: 10 } }, [
                  _vm._v(" " + _vm._s(club.address) + "... "),
                ]),
                _c(
                  "a-col",
                  { staticClass: "gx-text-right", attrs: { span: 8 } },
                  [
                    club.members &&
                    club.members.length &&
                    (club.members[0].pivot.status === "decline" ||
                      club.members[0].pivot.status === "archive")
                      ? _c(
                          "a-button",
                          {
                            attrs: { block: "", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.requestToJoin(club.id)
                              },
                            },
                          },
                          [_vm._v("\n          Request to Join\n        ")]
                        )
                      : _vm._e(),
                    club.members && !club.members.length
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "danger", block: "", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.requestToJoin(club.id)
                              },
                            },
                          },
                          [[_vm._v("Request to Join")]],
                          2
                        )
                      : _vm._e(),
                    club.members &&
                    club.members.length &&
                    (club.members[0].pivot.status === "invite" ||
                      club.members[0].pivot.status === "accept" ||
                      club.members[0].pivot.status === "request")
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "danger", block: "", size: "small" },
                          },
                          [
                            club.members[0].pivot.status === "invite"
                              ? [_vm._v("Invitation Pending")]
                              : _vm._e(),
                            club.members[0].pivot.status === "request"
                              ? [_vm._v("Request Sent\n          ")]
                              : _vm._e(),
                            club.members[0].pivot.status === "accept"
                              ? [_vm._v("Already a Member")]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.clubs.length < 1 && _vm.error_msg
            ? _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { staticClass: "gx-text-center", attrs: { xs: 18 } },
                    [_vm._v("\n        No result found\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }